<template>
  <modal
    v-if="isShow"
    class="modal--export"
    :title="translate(translations, 'export')"
    :activate="isShow"
    @activate="closeModal"
  >
    <template slot="body">
      <div class="form">
        <div class="form__head">
          <div class="form__head-item">
            <div class="form__item -dateby-input">
              <date-time-picker
                ref="datetimepickerFrom"
                v-model="dateFrom"
                mode="from"
                type="datetime"
                :placeholder="translate(translations, 'from')"
                :range="true"
                :set-max="dateTo"
              />
            </div>
          </div>

          <div class="form__head-item">
            <div class="form__item">
              <date-time-picker
                ref="datetimepickerTo"
                v-model="dateTo"
                mode="to"
                type="datetime"
                :placeholder="translate(translations, 'to')"
                :range="true"
                :set-min="dateFrom"
              />
            </div>
          </div>
        </div>

        <div class="form__body">
          <div
            v-for="col in allCols"
            :key="col.value"
            class="form__item"
          >
            <checkbox :label="translations ? translate(translations, col.display) : col.display">
              <input
                v-model="exportKeys"
                type="checkbox"
                :value="col.value"
              >
            </checkbox>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <checkbox
        v-if="testerCheckbox"
        label="Tester"
        style="margin-right: auto;"
      >
        <input
          v-model="exportKeys"
          type="checkbox"
          value="users.isTester"
        >
      </checkbox>

      <button
        class="btn--export-csv"
        @click="exportData"
      >
        <i class="ico--export"></i>
        <span>
          {{ translate(translations, 'export') }}
        </span>
      </button>
    </template>
  </modal>
</template>

<script>
//= jquery
import $ from 'jquery'

//= components
import Checkbox from '@/components/base/Checkbox'
import Modal from '@/components/base/Modal'
import DateTimePicker from '@/components/base/DateTimePicker'
import { isEmpty } from 'lodash'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'Export',

  components: {
    Checkbox,
    Modal,
    DateTimePicker
  },

  props: {
    module: String,

    activate: Boolean,

    allCols: [
      String,
      Array,
      Object
    ],

    testerCheckbox: {
      type: Boolean,
      default: false
    },

    translation: [
      Array,
      Object,
      String
    ]
  },

  data () {
    return {
      isShow: false,
      exportKeys: [],
      dateFrom: null,
      dateTo: null,
      translations: {}
    }
  },

  watch: {
    activate (val) {
      if (val) {
        this.isShow = true
      }

      if (!val) {
        this.isShow = false
      }
    }
  },

  created () {
    this.isShow = this.activate

    this.translations = Object.assign({}, this.translation)
  },

  methods: {
    closeModal () {
      this.isShow = false
      this.$emit('activate', this.isShow)
      $('body').removeAttr('style')
      $('body').removeClass('modal--open')
    },

    download (content, fileName, contentType) {
      const a = document.createElement('a')
      const file = new Blob([content], { type: contentType })
      a.href = URL.createObjectURL(file)
      a.download = fileName
      a.click()
    },

    async exportData () {
      if (isEmpty(this.dateFrom) || isEmpty(this.dateTo)) {
        return this.$alert('error', 'Dates cannot be left empty')
      }

      if (isEmpty(this.exportKeys)) {
        return this.$alert('error', 'Please select atleast one column')
      }

      const params = {
        column: JSON.stringify(_.isEmpty(this.exportKeys) ? '' : this.exportKeys),
        date_from: this.dateFrom,
        date_to: this.dateTo
      }

      this.$emit('trigger', params)
    }
  }
}
</script>
