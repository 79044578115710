import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    from: 'from',
    to: 'to',

    ip_management: 'ip management',

    //= tooltips
    add_user: 'add user',
    message: 'message',
    delete: 'delete',
    restore: 'restore',
    restore_confirm: 'restore(confirm)',
    restore_stop: 'restore(restore)',
    register_confirm: 'confirm register',
    save: 'save',
    confirm: 'confirm',
    reject: 'reject',
    waiting: 'waiting',
    export: 'export',
    import: 'import',
    all: 'all',

    //= tables
    edit_table_settings: 'edit table settings',
    no: 'no',
    created_date: 'created',
    register_date: 'request',
    login_id: 'login id',
    login_name: 'login name',
    level: 'level',
    lv: 'Lv',
    agent: 'agent',
    email: 'email',
    phone: 'phone',
    vip: 'VIP',
    status: 'status',
    bank_type: 'bank type',
    bank_name: 'bank name',
    bank_account: 'bank account',
    bank_number: 'bank number',
    referrer: 'referrer',
    code: 'code',
    group: 'group',
    balance: 'balance',
    com: 'com.',
    cash_in: 'cash in',
    cash_out: 'cash out',
    cash_net: 'cash net',
    bet_sports: 'bet sports',
    bet_vir: 'bet vir',
    bet_mini: 'bet mini',
    bet_slot: 'bet slot',
    total_bet: 'total bet',
    win_sports: 'win sports',
    win_vir: 'win vir',
    win_mini: 'win mini',
    win_slot: 'win slot',
    total_win: 'total win',
    updated_date: 'updated',
    deleted_date: 'deleted',
    memo: 'memo',
    points: 'points',

    pending: 'pending',
    confirmed: 'confirmed',
    banned: 'banned',
    rejected: 'rejected',
    deleted: 'deleted',
    waiting: 'waiting',
    request: 'requests',
    online: 'online',
    tester: 'testers',

    changeagent: 'changeagent',
    //= vip
    normal: 'normal',
    vvip: 'vvip',

    // level
    /* 'lv.1': `Lv.1`,
    'lv.2': `Lv.2`,
    'lv.3': `Lv.3`,
    'lv.4': `Lv.4`,
    'lv.5': `Lv.5`,
    'lv.6': `Lv.6`,
    'lv.7': `Lv.7`, */
    admin: 'admin',

    //= create modal
    create_user: 'create user',
    user_count: 'user count',
    password: 'password',
    bank_account_name: 'bank account name',
    bank_password: 'bank password',
    bank_name: 'bank name',
    currency: 'currency',
    create: 'create',

    //= message modal
    to_all: 'to all',
    enabled: 'enabled',
    disabled: 'disabled',

    send_all: 'enabled',
    send_all_off: 'disabled',
    send_agent: 'to agent',

    to_level: 'to level',
    select_level: 'select level',
    to_agent: 'to agent',
    from_agent: 'from agent',
    select_agent: 'select agent',
    to_code: 'to code',
    select_code: 'select code',
    to_group: 'to group',
    select_group: 'select group',
    to_domain: 'to domain',
    select_domain: 'selec domain',
    no_data: 'no data',

    subject_here: 'subject here...',
    content_here: 'content here...',
    send: 'send',
    modify: 'modify',

    change_user_agent: 'change user agent',
    apply_to_all: 'apply to all',
    message_to_user: 'message to user',

    bank_details: 'bank details',
    create_test_user: 'create test user',

    error_user_exist: 'The user created already exists.',

    select_user: 'please select user',
    choose_recipient: 'choose recipient',
    domain: 'domain',
    ip: 'ip',
    device: 'device',
    mo: 'mobile',
    pc: 'PC',
    logout: 'logout',
    force_logout: 'Force Logout',
    last_visit_page: 'last visit page',

    confirmed_date: 'confrimed',
    bank_group: 'bank group',
    bet_365: 'bet 365',
    bet_token: 'bet token',
    win_365: 'win 365',
    win_token: 'win token',

    new_code: 'code',

    new_created_at: 'created date'
  }
}
