<template>
  <div class="wrap__contents users-inner">
    <div class="toolbar">
      <div class="toolbar__items searchbar">
        <search
          :translations="translations"
          :date-by="dateBy"
          :filter-by="filterBy"
          @trigger="trigger"
          @refresh="search"
          @clear="search"
        />
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :is-loading="isDataLoading"
        :is-empty="isEmpty"
      />

      <tbl
        v-else
        ref="tbl"
        :data-loading="isDataLoading"
      >
        <template slot="head">
          <tbl-td
            v-if="checkCol('id', tblSetting)"
            name="no"
            :sort="true"
            @click.native="sort('id')"
          >
            {{ translate(translations, 'no') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' || getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('confirmed_at', tblSetting)"
            name="created-date"
            :sort="true"
            @click.native="sort('confirmed_at')"
          >
            <span v-if="getRouteStatus === 'register' || getRouteStatus === 'pending'">
              {{ translate(translations, 'register date') }}
            </span>

            <span v-else>
              {{ translate(translations, 'new created at') }}
            </span>
          </tbl-td>

          <tbl-td
            :sort="true"
            name="referrer"
            @click.native="sort('referrers.login_name')"
          >
            {{ translate(translations, 'referrer') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('login_id', tblSetting)"
            name="login-id"
            :sort="true"
            @click.native="sort('login_id')"
          >
            {{ translate(translations, 'login id') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('login_name', tblSetting)"
            name="login-name"
            :sort="true"
            @click.native="sort('login_name')"
          >
            {{ translate(translations, 'login name') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('level', tblSetting)"
            name="level"
            :sort="true"
            @click.native="sort('level_id')"
          >
            {{ translate(translations, 'level') }}
          </tbl-td>

          <tbl-td
            v-if="checkCol('code', tblSetting) || (getRouteStatus === 'register' || getRouteStatus === 'pending' || getRouteStatus === 'waiting')"
            name="code"
            :sort="true"
            @click.native="sort('code')"
          >
            {{ translate(translations, 'code') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('vip', tblSetting)"
            name="vip"
            :sort="true"
            @click.native="sort('vip_id')"
          >
            {{ translate(translations, 'vip') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('amount', tblSetting)"
            name="balance"
            :sort="true"
            @click.native="sort('amount')"
          >
            {{ translate(translations, 'balance') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('points', tblSetting)"
            name="points"
            :sort="true"
            @click.native="sort('point')"
          >
            {{ translate(translations, 'points') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_cash_in', tblSetting)"
            name="total-cash-in"
            :sort="true"
            @click.native="sort('total_cash_in')"
          >
            {{ translate(translations, 'cash in') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_cash_out', tblSetting)"
            name="total-cash-out"
            :sort="true"
            @click.native="sort('total_cash_out')"
          >
            {{ translate(translations, 'cash out') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_cash_net', tblSetting)"
            name="total-cash-net"
            :sort="true"
            @click.native="sort('total_cash_net')"
          >
            {{ translate(translations, 'cash net') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_bet', tblSetting)"
            name="total-bet"
            :sort="true"
            @click.native="sort('total_bet')"
          >
            {{ translate(translations, 'total bet') }}
          </tbl-td>

          <tbl-td
            v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_win', tblSetting)"
            name="total-win"
            :sort="true"
            @click.native="sort('total_win')"
          >
            {{ translate(translations, 'total win') }}
          </tbl-td>

          <tbl-td
            v-if="getRouteStatus === 'register' || getRouteStatus === 'pending' || getRouteStatus === 'waiting'"
            name="actions"
          />
        </template>

        <template slot="body">
          <tbl-row
            v-for="(user, x) in users"
            :key="x"
            :class="{'is-deleted': user.deleted_at}"
          >
            <tbl-td
              v-if="checkCol('id', tblSetting)"
              name="no"
            >
              {{ generateNumber(x, params.page, params.rows, params.sort, uCount || 0) }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' || getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('confirmed_at', tblSetting)"
              name="created-date"
            >
              {{ user.created_at | date('YYYY-MM-DD HH:mm') }}
            </tbl-td>

            <tbl-td
              name="referrer"
              @click.native="openUser(user.referrer && user.referrer.id, 'cash-history/all/all')"
            >
              <template v-if="user.referrer">
                {{ user.referrer.login_name }}
              </template>

              <template v-if="!user.referrer">
                -
              </template>
            </tbl-td>

            <tbl-td
              v-if="checkCol('login_id', tblSetting)"
              name="login-id"
              @click.native="openUser(user.id, 'cash-history/all/all')"
            >
              {{ user.login_id }}
            </tbl-td>

            <tbl-td
              v-if="checkCol('login_name', tblSetting)"
              name="login-name"
              @click.native="openUser(user.id, 'bet-history/all')"
            >
              {{ user.login_name }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('level', tblSetting)"
              name="level"
            >
              <select2
                v-if="checkReadWrite && levels.length && !user.deleted_at"
                id="filter-by"
                ref="filter"
                v-model="user.level_id"
                class="select-list--filter"
                :with-reset="true"
                @reset="resetTrigger(user)"
                @change.native="setSelected(user)"
              >
                <option2
                  selected
                  hidden
                >
                  {{ translate(translations, user.level.name) }}
                </option2>

                <option2
                  v-for="level in levels"
                  :key="level.id"
                  :value="level.id"
                  :selected="user.level_id === level.id"
                >
                  {{ translate(translations, level.name) }}
                </option2>
              </select2>

              <template v-else>
                {{ translate(translations, user.level.name) }}
              </template>
            </tbl-td>

            <tbl-td
              v-if="checkCol('code', tblSetting) || (getRouteStatus === 'register' || getRouteStatus === 'pending' || getRouteStatus === 'waiting')"
              name="code"
              @click.native="codeId = user.code.id"
            >
              {{ user.code.code }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('vip', tblSetting)"
              name="vip"
            >
              <select2
                v-if="checkReadWrite && vipList.length && !user.deleted_at"
                v-model="user.vip_id"
                :with-reset="true"
                @reset="resetTrigger(user)"
                @change.native="setSelected(user)"
              >
                <option2
                  v-for="vip in vipList"
                  :key="vip.id"
                  :value="vip.id"
                  :selected="user.vip_id === vip.id"
                >
                  {{ translate(translations, vip.name) }}
                </option2>
              </select2>

              <template v-else>
                {{ translate(translations, user.vip.name) }}
              </template>
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('amount', tblSetting)"
              name="balance"
              class="is-amount"
              @click.native="openUser(user.id, 'cash-history/all')"
            >
              {{ user.amount | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('points', tblSetting)"
              name="points"
              class="is-amount"
              @click.native="openUser(user.id, 'point-history/all')"
            >
              {{ user.point | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_cash_in', tblSetting)"
              name="total-cash-in"
              class="is-amount"
              @click.native="openUser(user.id, 'cash-history/cash-in')"
            >
              {{ user.total_cash_in | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_cash_out', tblSetting)"
              name="total-cash-out"
              class="is-amount"
              @click.native="openUser(user.id, 'cash-history/cash-out')"
            >
              {{ user.total_cash_out | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_cash_net', tblSetting)"
              name="total-cash-net"
              class="is-amount"
              @click.native="openUser(user.id, 'cash-history/all')"
            >
              {{ user.total_cash_net | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_bet', tblSetting)"
              name="total-bet"
              class="is-amount"
              @click.native="openUser(user.id, 'transaction-history/all')"
            >
              {{ user.total_bet | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus !== 'register' && getRouteStatus !== 'pending' && getRouteStatus !== 'waiting') && checkCol('total_win', tblSetting)"
              name="total-win"
              class="is-amount"
              @click.native="openUser(user.id, 'transaction-history/all')"
            >
              {{ user.total_win | FormatAmount('whole') }}
            </tbl-td>

            <tbl-td
              v-if="(getRouteStatus === 'register' || getRouteStatus === 'pending' || getRouteStatus === 'waiting')"
              name="actions"
            >
              <button
                class="btn--restore"
                @click="selected.push(user.id), user.status = 'C', update()"
              >
                <span>
                  {{ translate(translations, 'confirm') }}
                </span>
              </button>

              <button
                v-if="user.status !== 'W'"
                class="btn--create"
                @click="selected.push(user.id), user.status = 'W', update()"
              >
                <span>
                  {{ translate(translations, 'waiting') }}
                </span>
              </button>

              <button
                class="btn--delete"
                @click="selected.push(user.id), user.status = 'R', update()"
              >
                <span>
                  {{ translate(translations, 'reject') }}
                </span>
              </button>
            </tbl-td>
          </tbl-row>
        </template>

        <template slot="footer">
          <pagination
            v-model="params.page"
            :total="uCount"
            :rows="params.rows"
            @page="trigger"
          />
        </template>
      </tbl>
    </div>

    <modal
      v-if="showCreate"
      class="modal--create-user"
      :title="translate(translations, 'create user')"
      :activate="showCreate"
      @activate="showCreate = $event"
    >
      <template slot="body">
        <div class="form">
          <div class="form__head">
            <div class="form__head-item">
              <div class="form__item">
                <div class="form__label">
                  {{ translate(translations, 'user count') }}
                </div>

                <div class="form__data">
                  <div class="input-wrap">
                    <input
                      v-model.number="count"
                      type="number"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="(user, x) in manualUsers"
            :key="x"
            class="form__body"
          >
            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'login id') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'login_id')}"
                >
                  <input
                    v-model="user.login_id"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'login name') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'login_name')}"
                >
                  <input
                    v-model="user.login_name"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'password') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'password')}"
                >
                  <input
                    v-model="user.password"
                    type="password"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'phone') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'phone')}"
                >
                  <input
                    v-model="user.phone"
                    type="number"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank type') }}
              </div>

              <div class="form__data">
                <select2
                  v-model="user.bank_type"
                  :with-reset="true"
                  @change.native="checkBanks(user)"
                >
                  <option2
                    v-for="type in bankTypes"
                    :key="type.id"
                    :value="type.id"
                    :selected="user.bank_type === type.id"
                  >
                    {{ translate(translations, type.name) }}
                  </option2>
                </select2>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'code') }}
              </div>

              <div class="form__data">
                <input-suggestion
                  v-model="user.code_id"
                  @search="searchCodes"
                >
                  <span slot="selected">
                    {{ user.code_id | userCode(allCodes) }}
                  </span>

                  <suggestion
                    v-for="code in allCodes"
                    slot="suggestion"
                    :key="code.id"
                    :value="code.id"
                  >
                    {{ code.name }}
                  </suggestion>
                </input-suggestion>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank name') }}
              </div>

              <div class="form__data">
                <select2
                  v-if="checkReadWrite"
                  v-model="user.bank_id"
                  :with-reset="true"
                >
                  <option2
                    v-for="bank in filteredBanks(user.bank_type)"
                    :key="bank.id"
                    :value="bank.id"
                    :selected="user.bank_id === bank.id"
                  >
                    {{ bank.name }}
                  </option2>
                </select2>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank account name') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'bank_account_name')}"
                >
                  <input
                    v-model="user.bank_account_name"
                    type="text"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank number') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'bank_number')}"
                >
                  <input
                    v-model="user.bank_number"
                    type="number"
                  >
                </div>
              </div>
            </div>

            <div class="form__item">
              <div class="form__label">
                {{ translate(translations, 'bank password') }}
              </div>

              <div class="form__data">
                <div
                  class="input-wrap"
                  :class="{'error': checkValidation(x, 'bank_password')}"
                >
                  <input
                    v-model="user.bank_password"
                    type="password"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          class="btn--confirm"
          @click="create()"
        >
          <i class="ico--create"></i>
          <span>{{ translate(translations, 'create') }}</span>
        </button>
      </template>
    </modal>

    <modal
      v-if="showChangeAgent"
      class="modal--change-agent"
      :title="translate(translations, 'change user agent')"
      :activate="showChangeAgent"
      @activate="showChangeAgent = $event"
    >
      <template slot="body">
        <div class="form">
          <div class="form__head">
            <div class="form__head-item">
              <div class="form__item">
                <div class="form__label">
                  {{ translate(translations, 'apply to all') }}
                </div>

                <div class="form__data">
                  <select2
                    v-model="changeAgentModel.agent"
                    :with-reset="true"
                  >
                    <option2
                      selected
                      hidden
                    >
                      {{ translate(translations, 'select agent') }}
                    </option2>
                    <option2
                      v-for="(agent, i) in agentCodesCopy.agents"
                      :key="i"
                      :value="agent.id"
                      :name="agent.login_name"
                    >
                      {{ agent.login_id }}
                    </option2>
                  </select2>

                  <select2
                    v-model="changeAgentModel.code"
                    :with-reset="true"
                  >
                    <option2
                      selected
                      hidden
                    >
                      {{ translate(translations, 'select code') }}
                    </option2>
                    <option2
                      v-for="(code, i) in agentCodesCopy.codes"
                      :key="i"
                      :value="code.id"
                      :name="code.name"
                    >
                      {{ code.name }}
                    </option2>
                  </select2>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="selectedUsers.length > 0"
            class="form__body"
            swal
          >
            <tbl>
              <template slot="head">
                <tbl-td name="login-id">
                  {{ translate(translations, 'login id') }}
                </tbl-td>

                <tbl-td name="login-name">
                  {{ translate(translations, 'login name') }}
                </tbl-td>

                <tbl-td name="from-agent">
                  {{ translate(translations, 'from agent') }}
                </tbl-td>

                <tbl-td name="to-agent">
                  {{ translate(translations, 'to agent') }}
                </tbl-td>

                <tbl-td name="to-code">
                  {{ translate(translations, 'to code') }}
                </tbl-td>
              </template>

              <template slot="body">
                <tbl-row
                  v-for="(user, i) in selectedUsers"
                  :key="user.id"
                >
                  <tbl-td name="login-id">
                    {{ user.login_id }}
                  </tbl-td>

                  <tbl-td name="login-name">
                    {{ user.login_name }}
                  </tbl-td>

                  <tbl-td name="from-agent">
                    {{ user.code.agent_id }}
                  </tbl-td>

                  <tbl-td name="to-agent">
                    <select2
                      v-model="agents[i].agent_id"
                      :title="translate(translations, 'Select agent') "
                      :with-reset="true"
                      @reset="resetAgent(agents[i])"
                    >
                      <option2
                        selected
                        hidden
                      >
                        {{ translate(translations, 'select agent') }}
                      </option2>
                      <option2
                        v-for="agent in agentsFilter(agentsCodesList.agents, agents[i].code_id)"
                        :key="agent.id"
                        :value="agent.id"
                        :name="agent.login_id"
                      >
                        {{ agent.login_id }}
                      </option2>
                    </select2>
                  </tbl-td>

                  <tbl-td name="to-agent">
                    <select2
                      v-model="agents[i].code_id"
                      :title="translate(translations, 'Select code') "
                      :with-reset="true"
                      @reset="resetCode(agents[i])"
                    >
                      <option2
                        selected
                        hidden
                      >
                        {{ translate(translations, 'select code') }}
                      </option2>
                      <option2
                        v-for="codes in codesFilter(agentsCodesList.codes, agents[i].agent_id)"
                        :key="codes.id"
                        :value="codes.id"
                        :name="codes.name"
                      >
                        {{ codes.name }}
                      </option2>
                    </select2>
                  </tbl-td>
                </tbl-row>
              </template>
            </tbl>
          </div>
        </div>
      </template>

      <template slot="footer">
        <button
          class="btn--modify"
          @click="updateAgent()"
        >
          <i class="ico--save"></i>
          <span>
            {{ translate(translations, 'modify') }}
          </span>
        </button>
      </template>
    </modal>

    <table-settings
      v-if="modConfig.tableConfig"
      :translations="translations"
      :show-tbl-settings="showTblSettings"
      :all-cols="allCols"
      :tbl-setting="tblSetting"
      :activate="showTblSettings"
      @activate="showTblSettings = $event"
      @load="showLoad()"
    />

    <code-popup
      :id="codeId"
      @close="codeId = null"
    />

    <export
      module="user"
      :translation="translations"
      :all-cols="exportCols"
      :activate="showExport"
      :tester-checkbox="testerCheckbox"
      @activate="showExport = $event"
      @trigger="exportData($event)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import { user } from '@/socket'

//= components
import Pagination from '@/components/base/Pagination'
import Modal from '@/components/base/Modal'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'
// import Message from '@/components/common/message/Message'
import CodePopup from '@/components/common/codes/CodePopup'
import Export from '@/components/base/import-export/Export'

//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'

//= mixin
import { checkAll } from '@/assets/js/mixins/base/CheckAll'
import siteDefaults from '@/assets/js/mixins/base/SiteDefaults'

//= translation
import { translations } from '@/assets/js/translations/Users'

export default {
  name: 'Users',

  components: {
    Pagination,
    Modal,
    Search,
    CheckDataList,
    CodePopup,
    Export
  },

  filters: {
    FormatAmount,
    userCode (id, codes) {
      const f = codes.find(e => {
        return e.id === id
      })
      if (f && Object.keys(f).length) {
        return f.name
      }
      return ''
    }
  },

  mixins: [
    checkAll,
    siteDefaults
  ],

  data () {
    const params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      testerCheckbox: true,
      translations,

      params,
      status: 'all',

      showCreate: false,
      showTblSettings: false,
      showChangeAgent: false,
      showMessageUser: false,
      showExport: false,

      count: 1,

      manualUsers: [],
      selected: [],
      selectAll: false,
      selectedUsers: [],

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,

      codeId: null,

      users: [],

      filterBy: [
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'level_id',
          display: 'level'
        },
        {
          value: 'code',
          display: 'code'
        }
      ],

      dateBy: [
        {
          value: 'confirmed_at',
          display: 'created at'
        }
      ],

      tblSetting: 'user_list',

      allCols: [
        {
          display: 'no',
          value: 'id'
        },
        {
          display: 'created date',
          value: 'confirmed_at'
        },
        {
          display: 'login id',
          value: 'login_id'
        },
        {
          display: 'login name',
          value: 'login_name'
        },
        {
          display: 'level',
          value: 'level'
        },
        {
          display: 'vip',
          value: 'vip'
        },
        {
          display: 'code',
          value: 'code'
        },
        {
          display: 'balance',
          value: 'amount'
        },
        {
          display: 'points',
          value: 'points'
        },
        {
          display: 'cash in',
          value: 'total_cash_in'
        },
        {
          display: 'cash out',
          value: 'total_cash_out'
        },
        {
          display: 'cash net',
          value: 'total_cash_net'
        },
        {
          display: 'total bet',
          value: 'total_bet'
        },
        {
          display: 'total win',
          value: 'total_win'
        }],

      exportCols: [
        {
          display: 'no',
          value: 'users.id'
        },
        {
          display: 'created date',
          value: 'users.created_at'
        },
        {
          display: 'login id',
          value: 'users.login_id'
        },
        {
          display: 'login name',
          value: 'users.login_name'
        },
        {
          display: 'level',
          value: 'users.level_id'
        },
        {
          display: 'code',
          value: 'codes.code'
        },
        {
          display: 'vip',
          value: 'users.vip_id'
        },
        {
          display: 'balance',
          value: 'users.amount as balance'
        },
        {
          display: 'points',
          value: 'users.point as points'
        },
        {
          display: 'cash in',
          value: 'users.total_cash_in'
        },
        {
          display: 'cash out',
          value: 'users.total_cash_out'
        },
        {
          display: 'cash net',
          value: 'users.total_cash_net'
        },
        {
          display: 'total bet',
          value: 'users.total_bet'
        },
        {
          display: 'total win',
          value: 'users.total_win'
        }
      ],

      agent: null,
      agents: [],

      validation: [],

      changeAgentModel: {
        code: null,
        agent: null
      },

      agentCodesCopy: {
        code: null,
        agent: null
      }
    }
  },

  computed: {
    ...mapState('user', { allUsers: 'users', uCount: 'count', oneUser: 'user' }),
    ...mapState('bank', ['banks', 'bankTypes']),
    ...mapState('vip', ['vipList']),
    ...mapState('level', ['levels']),
    ...mapState('code', { allCodes: 'codes' }),
    ...mapState('agent', { agentList: 'agents', agentsCodesList: 'agentsCodesList' }),
    ...mapState('deposit-account', ['depositAccounts']),
    ...mapState('user-export', ['userExportData']),

    getRouteStatus () {
      return this.$route.params.status
    }
  },

  watch: {
    '$route' (to) {
      this.search(this.generate(to.query))
    },

    count () {
      this.createPopup()
    },

    selected () {
      this.selectedUsers = this.users.filter(e => {
        return this.selected.indexOf(e.id) > -1
      })
    },

    'changeAgentModel.agent' (e) {
      const codes = JSON.parse(JSON.stringify(this.agentsCodesList.codes))
      if (e === null || !e) {
        this.agentCodesCopy.codes = codes
        return
      }
      this.agentCodesCopy.codes = codes.filter(agent => {
        return parseInt(agent.agent_id) === parseInt(e)
      })
    },

    'changeAgentModel.code' (e) {
      const code = this.agentsCodesList.codes.find(c => {
        return parseInt(c.id) === parseInt(e)
      })
      const agents = JSON.parse(JSON.stringify(this.agentsCodesList.agents))
      if (e === null || !e) {
        this.agentCodesCopy.agents = agents
        return
      }
      this.agentCodesCopy.agents = agents.filter(agent => {
        return parseInt(agent.id) === parseInt(code.agent_id)
      })
    }
  },

  async created () {
    this.route = this.$route

    if (this.$route.params.status === undefined) {
      this.$router.push(`${this.$route.path}/all`)
    }

    this.params = this.generate(this.params)

    this.search()

    await this.getAgentCode()
    this.agentCodesCopy = JSON.parse(JSON.stringify(this.agentsCodesList))

    this.getBanks({ status: 'active' })
    this.getVips()
    this.getLevels()
    this.getCodes({ status: 'active' })
    this.getAccounts({ status: 'all' })
  },

  mounted () {
    user.on('register', data => {
      data = JSON.parse(data)
      const test = this.users.slice(0)

      test.pop()
      test.unshift(data)
      this.SET_USERS(test)
      this.users = JSON.parse(JSON.stringify(this.allUsers))
    })

    user.on('login', this.loginEvt)

    user.on('logout', this.logoutEvt)
  },

  beforeDestroy () {
    user.removeListener('register')
    user.removeListener('login', this.loginEvt)
    user.removeListener('logout', this.logoutEvt)
  },

  methods: {
    ...mapMutations({
      SET_USERS: 'user/SET_USERS',
      ADD_USER: 'user/ADD_USER',
      REMOVE_USER: 'user/REMOVE_USER',
      SET_USERCOUNT: 'user/SET_USERCOUNT'
    }),

    ...mapActions({
      createMemo: 'userMemo/create',

      getCount: 'user/getCount',
      setAgent: 'user/setAgent',
      findUser: 'user/find',
      createUsers: 'user/create',
      patch: 'user/update',
      getUsers: 'user/get',
      deleteUsers: 'user/delete',

      getBanks: 'bank/get',

      getVips: 'vip/get',

      getLevels: 'level/get',

      getCodes: 'code/get',
      getCodeArchive: 'code/archive',

      getAccounts: 'deposit-account/get',
      getAccountArchive: 'deposit-account/archive',

      getAgentArchive: 'agent/archive',
      getAgentCode: 'agent/getAgentCode',

      getAllMeta: 'meta/get',

      createLog: 'admin-logs/post',

      getUserExportData: 'user-export/getUserExportData'
    }),

    logoutEvt (id) {
      if (this.$route.params.status !== 'online') {
        return
      }
      this.REMOVE_USER(id)

      if (this.users.length) {
        this.isEmpty = false
      } else {
        this.isEmpty = true
        this.SET_USERCOUNT(0)
      }

      this.users = JSON.parse(JSON.stringify(this.allUsers))
    },

    async loginEvt (id) {
      if (this.$route.params.status !== 'online') {
        return
      }
      await this.findUser({ id })
      this.ADD_USER(this.oneUser)
      this.users = JSON.parse(JSON.stringify(this.allUsers))

      if (this.users.length) {
        this.isEmpty = false
      }
    },

    codesFilter (list, ee) {
      if (!ee) {
        return list
      }

      return list.filter(e => {
        return parseInt(e.agent_id) === parseInt(ee)
      })
    },

    agentsFilter (list, ee) {
      if (!ee) {
        return list
      }

      let codes = JSON.parse(JSON.stringify(this.agentsCodesList.codes))
      codes = codes.find(e => {
        return parseInt(ee) === parseInt(e.id)
      })
      return list.filter(e => {
        return parseInt(e.id) === parseInt(codes.agent_id)
      })
    },

    searchCodes (q) {
      const params = {
        rows: 30,
        sort_by: 'id',
        sort: 'desc'
      }

      if (q) {
        params.filter_by = 'login_id'
        params.q = q
        params.sort = 'asc'
      }

      this.getCodes({
        status: 'active',
        params
      })
    },

    checkBanks (user) {
      user.bank_id = this.filteredBanks(user.bank_type)[0].id
    },

    filteredBanks (id) {
      if (!id) {
        return this.banks
      }

      return this.banks.filter(e => {
        return parseInt(e.type_id) === parseInt(id)
      })
    },

    trigger (query) {
      if (_.isEqual(query, this.params)) {
        this.search()
        return
      }
      this.params = query || this.params
      this.$router.push({ query: this.params })
    },

    async search (query) {
      this.params = query || this.params

      this.status = this.$route.params.status

      this.selected = []

      if (this.$route.name === 'agents-users') {
        this.params.agentUserId = this.$route.params.id
      }

      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      await this.getUsers({
        status: this.status,
        params: this.params
      })

      await this.getCount({
        status: this.status,
        params: this.params
      })

      this.users = JSON.parse(JSON.stringify(this.allUsers))

      // == flags set
      this.isDataLoading = false
      this.showDataLoading = false

      if (!this.users.length) {
        this.isEmpty = true
      }
    },

    update () {
      const success = async () => {
        const keys = ['login_id', 'login_name', 'email', 'level_id', 'vip_id', 'status', 'bet_limits', 'settings']

        this.showDataLoading = true

        const edited = this.users.filter(e => {
          return this.selected.indexOf(e.id) > -1
        })

        for (let x = 0; x < edited.length; x++) {
          const data = {}

          for (const key in edited[x]) {
            if (keys.indexOf(key) > -1) {
              data[key] = edited[x][key]
            }
          }

          try {
            await this.patch({ data: data, id: edited[x].id })
            this.createLog({ action: 'updated', origin: 'users' })
            if (edited[x].memo) {
              await this.createMemo({ memo: edited[x].memo, id: edited[x].id })
              this.createLog({ action: 'insert', origin: 'users memo' })
            }
          } catch (err) {
            console.log(err)
          }
        }

        this.selectAll = false
        this.showDataLoading = false
        this.search()
        this.getAllMeta()

        if (!this.users.length) {
          this.isEmpty = true
        }
      }

      // 'Are you sure you want to modify the following user(s)?'
      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, { success }, 'Modify')
    },

    sort (sortBy) {
      if (this.params.sort === 'asc') {
        this.params.sort = 'desc'
      } else {
        this.params.sort = 'asc'
      }

      this.params.sort_by = sortBy

      if (!this.showDataLoading) {
        this.showDataLoading = true
      }

      this.$router.push({ query: this.params })
    },

    async updateAgent () {
      if (this.changeAgentModel.code) {
        for (let x = 0; x < this.selectedUsers.length; x++) {
          await this.setAgent({
            user_id: this.selectedUsers[x].id,
            agent_id: this.changeAgentModel.agent,
            code_id: this.changeAgentModel.code
          })
        }
      } else {
        for (let x = 0; x < this.agents.length; x++) {
          await this.setAgent({
            user_id: this.agents[x].user_id,
            agent_id: this.agents[x].agent_id,
            code_id: this.agents[x].code_id
          })
        }
      }
      this.agents = []
      this.search()
      this.showChangeAgent = false

      const agents = JSON.parse(JSON.stringify(this.agentsCodesList.agents))
      const codes = JSON.parse(JSON.stringify(this.agentsCodesList.codes))
      this.agentCodesCopy.agents = agents
      this.agentCodesCopy.codes = codes
    },

    createPopup () {
      this.showCreate = true

      this.manualUsers = []
      for (let x = 0; x < this.count; x++) {
        this.manualUsers.push({
          login_id: '',
          login_name: '',
          // email: '',
          phone: '',
          password: '',
          bank_type: 1,
          bank_account_name: '',
          bank_number: '',
          bank_password: '',
          bank_id: 1,
          currency_id: 3,
          code_id: 1
        })
      }
    },

    checkValidation (val, key) {
      if (this.validation.length) {
        const filter = this.validation.filter(e => {
          return parseInt(e.index) === parseInt(val)
        })

        const find = filter.find(e => {
          return e.value === key
        })

        return !!find
      }
      return false
    },

    create () {
      const success = async () => {
        try {
          for (let x = 0; x < this.manualUsers.length; x++) {
            for (const key in this.manualUsers[x]) {
              if (this.manualUsers[x][key] === '') {
                this.validation.push({
                  index: x,
                  value: key
                })
              }
            }
          }

          if (this.validation.length) {
            const swalMsg = 'try again'
            this.$alert('error', swalMsg, {}, '')

            setTimeout(() => {
              this.validation = []
            }, 3000)
            return
          }
          await this.createUsers(this.manualUsers)

          // 'You have successfully created a new user'
          const swalMsg = 'success create'

          this.$alert('success', swalMsg, {})
          this.createLog({ action: 'create', origin: 'users' })

          this.showCreate = false
          this.showDataLoading = true
          this.search()
          // await this.getUsers({ status: this.status, params: this.params })
          // this.users = JSON.parse(JSON.stringify(this.allUsers))
        } catch (err) {
          const swalMsg = this.translate(translations, 'error user exist')
          this.$alert('error', swalMsg, {}, '', 'Error')
        }
      }

      // 'Are you sure you want create user?'
      const swalMsg = ''
      this.$alert('prompt', swalMsg, {}, { success }, 'create')
    },

    setSelected (user) {
      this.$nextTick(() => {
        const result = this.allUsers.find(row => {
          return row.id === user.id
        })

        const idx = this.selected.indexOf(user.id)

        if (user.level_id === result.level_id &&
            user.vip_id === result.vip_id &&
            user.status === result.status &&
            idx > -1) {
          this.selected.splice(idx, 1)
          return
        }

        if (user.level_id === result.level_id &&
          user.vip_id === result.vip_id &&
          user.status === result.status) {
          return
        }

        this.selected.push(user.id)
        this.selected = this.selected.filter((val, index, arr) => {
          return arr.indexOf(val) === index
        })
      })
    },

    resetAgent (agent) {
      agent.agent_id = null
    },

    resetCode (agent) {
      agent.code_id = null
    },

    resetTrigger (user) {
      this.$nextTick(() => {
        let count = 0
        const idx = this.selected.indexOf(user.id)

        const result = this.allUsers.find(row => {
          return row.id === user.id
        })

        if (result.level_id !== user.level_id) {
          count += 1
        }

        if (result.vip_id !== user.vip_id) {
          count += 1
        }

        if (result.status !== user.status) {
          count += 1
        }

        if (count === 0 && idx > -1) {
          this.selected.splice(idx, 1)
        }
      })
    },

    download (content, fileName, contentType) {
      const a = document.createElement('a')
      const file = new Blob([content], { type: contentType })
      a.href = URL.createObjectURL(file)
      a.download = fileName
      a.click()
    },

    async exportData (data) {
      await this.getUserExportData(data)

      const { result, name } = this.userExportData
      return this.download(`\uFEFF${result}`, name, 'text/csv;charset=utf-8')
    }
  }
}
</script>
