export const kr = {
  kr: {
    from: '에서',
    to: '까지',

    ip_management: 'IP 관리',

    //= tooltips
    add_user: '유저추가',
    message: '쪽지',
    delete: '삭제',
    restore: '복원',
    restore_confirm: '복원(정상)',
    restore_stop: '복원(중지)',
    register_confirm: '가입승인',
    save: '저장',
    confirm: '확인',
    reject: '거부',
    waiting: '대기',
    export: '내보내기',
    import: '가져오기',
    all: '전체',
    confirmed_date: '가입일자',

    //= tables
    edit_table_settings: '테이블 설정 수정',
    no: '번호',
    created_at: '등록일자',

    register_date: '신청일자',

    login_id: '아이디',
    login_name: '닉네임',
    level: '레벨',
    lv: '레벨',
    agent: '매장',
    email: '이메일',
    phone: '연락처',
    vip: 'VIP',
    status: '상태',
    bank_type: '은행 구분',
    bank_name: '은행명',
    bank_account: '예금주',
    bank_number: '계좌번호',
    referrer: '추천인',
    code: '코드',
    group: '그룹',
    balance: '보유금액',
    commission: '커미션',
    cash_in: '충전',
    cash_out: '환전',
    cash_net: '입출손익',
    bet_sports: '배팅(스포츠)',
    bet_vir: '배팅(가상)',
    bet_mini: '배팅(미니)',
    bet_slot: '배팅(슬롯)',
    total_bet: '배팅합계',
    win_sports: '당첨(스포츠)',
    win_vir: '당첨(가상)',
    win_mini: '당첨(미니)',
    win_slot: '당첨(솔롯)',
    total_win: '당첨합계',
    updated_date: '수정일자',
    deleted_date: '삭제일자',
    memo: '메모',
    points: '포인트',

    pending: '요청',
    confirmed: '정상',
    banned: '중지',
    rejected: '거부',
    deleted: '삭제',
    request: '가입요청',
    online: '온라인',
    tester: '테스터',

    changeagent: '매장 변경',
    //= vip
    normal: '일반',
    vvip: 'VVIP',

    lv: '레벨',
    'lv.1': '레벨 1',
    'lv.2': '레벨 2',
    'lv.3': '레벨 3',
    'lv.4': '레벨 4',
    'lv.5': '레벨 5',
    'lv.6': '레벨 6',
    'lv.7': '레벨 7',
    'lv.8': '레벨 8',
    'lv.9': '레벨 9',
    'lv.10': '레벨 10',
    'lv.11': '레벨 11',
    'lv.12': '레벨 12',
    'lv.13': '레벨 13',
    'lv.14': '레벨 14',
    'lv.15': '레벨 15',
    'lv.16': '레벨 16',
    'lv.17': '레벨 17',
    'lv.18': '레벨 18',
    'lv.19': '레벨 19',
    'lv.20': '레벨 20',
    'lv.21': '레벨 21',

    admin: '관리자',

    //= create modal
    create_user: '신규유저 등록',
    user_count: '유저 수',
    password: '비밀번호',
    bank_account_name: '은행 예금주명',
    bank_password: '출금비밀번호',
    currency: '통화',
    create: '등록',

    //= message modal
    to_all: '전체 전송',
    enabled: '허용',
    disabled: '차단',

    send_all: '사용',
    send_all_off: '중지',
    send_agent: '매장 전송',

    to_level: '레벨 전송',
    select_level: '레벨 선택',
    to_agent: '변경 매장',
    from_agent: '현재 소속',
    select_agent: '매장 선택',
    to_code: '코드 전송',
    select_code: '코드 선택',
    to_group: '그룹 전송',
    select_group: '그룹 선택',
    to_domain: '도메인 전송',
    select_domain: '도메인 선택',
    no_data: '데이타 없음',

    subject_here: '제목',
    content_here: '내용',
    send: '전송',
    modify: '수정',

    change_user_agent: '유저 매장변경',
    apply_to_all: '모두 적용',
    message_to_user: '쪽지 보내기',

    bank_details: '유저 매장변경',
    create_test_user: '테스트 유저 등록',

    error_user_exist: '이미 존제하는 계정입니다.',

    select_user: '사용자를 선택하세요.',
    choose_recipient: '받는 사람을 선택하세요',
    domain: '도메인',
    ip: '아이피',
    device: '접속 기기',
    mo: '모바일',
    pc: 'PC',
    force_logout: '강제 로그아웃',
    select_all: '전체선택',
    last_visit_page: '위치',

    code_group: '코드 그룹',
    code: '계좌그룹',
    level_group: '레벨 그룹',

    confirmed_at: '처리일자',
    bank_group: '계좌그룹',
    bet_365: '배팅365',
    bet_token: '배팅토큰',
    win_365: '당첨365',
    win_token: '당첨토큰',
    betting_sport: '스포츠 배팅진행',
    betting_mini: '미니게임 배팅진행',
    betting_casino: '카지노 배팅진행',
    betting_token: '토큰 배팅진행',
    betting_365: 'BET365 배팅진행',

    modify_bank_passwords: '테스트 회원 출금 비밀번호 전체 변경',
    modify_passwords: '테스트 회원 비밀번호 전체 변경',
    login_device: '접속기기',

    action: '상태',
    os_name: '운영체제',
    os_version: '버전',
    browser_name: '브라우져',
    browser_version: '브라우져버전',
    password_incorrect: '비밀번호 오류',
    login: '로그인',
    logout: '로그아웃',
    access_logs_history: '접속 로그 내역',

    duplicate_list: '중복목록',

    last_login_date: '마지막 로그인',
    last_login: '마지막 로그인',

    updated: `수정일자`,

    chooose_recipient: '쪽지 수신자를 선택해 주세요',
    com: '커미션',

    new_code: '코드',

    new_created_at: '신청일자'
  }
}
