import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    from: `から`,
    to: `まで`,

    ip_management: `IP 管理`,

    //= tooltips
    add_user: `ユーザー追加`,
    message: `メッセージ`,
    delete: `削除`,
    restore: `復元`,
    restore_confirm: `復元(正常)`,
    restore_stop: `復元(中止)`,
    register_confirm: `加入承認`,
    save: `保存`,
    confirm: `確認`,
    reject: `拒否`,
    waiting: `待機`,
    export: `出力`,
    import: `入力`,
    all: `全体`,

    //= tables
    edit_table_settings: `テーブル設定の修正`,
    no: `ナンバー`,
    created_date: `登録日時`,
    register_date: `申請日時`,
    login_id: `ID`,
    login_name: `ニックネーム`,
    level: `レベル`,
    lv: `レベル`,
    agent: `店舗`,
    email: `Eメール`,
    phone: `電話番号`,
    vip: `VIP`,
    status: `状態`,
    bank_type: `銀行タイプ`,
    bank_name: `銀行名`,
    bank_account: `口座名義`,
    bank_number: `口座番号`,
    referrer: `推薦人`,
    code: `コード`,
    group: `グループ`,
    balance: `保有金額`,
    com: `コミッション`,
    cash_in: `チャージ`,
    cash_out: `換金`,
    cash_net: `入出損益`,
    bet_sports: `ベッティング スポーツ`,
    bet_vir: `ベッティング 仮想`,
    bet_mini: `ベッティング ミニ`,
    bet_slot: `ベッティング スロット`,
    total_bet: `ベッティング 合計`,
    win_sports: `勝ち スポーツ`,
    win_vir: `勝ち 仮想`,
    win_mini: `勝ち ミニ`,
    win_slot: `勝ち スロット`,
    total_win: `勝ち合計`,
    updated_date: `修正日時`,
    deleted_date: `削除日時`,
    memo: `メモ`,
    points: `ポイント`,

    pending: `要請`,
    confirmed: `正常`,
    banned: `中止`,
    rejected: `拒否`,
    deleted: `削除`,
    waiting: `待機`,
    request: `加入要請`,
    online: `オンライン`,
    tester: `テスター`,

    changeagent: `店舗変更`,
    //= vip
    normal: `一般l`,
    vvip: `vvip`,

    // level
    /* 'lv.1': `レベル1`,
    'lv.2': `レベル2`,
    'lv.3': `レベル3`,
    'lv.4': `レベル4`,
    'lv.5': `レベル5`,
    'lv.6': `レベル6`,
    'lv.7': `レベル7`, */
    admin: `管理者`,

    //= create modal
    create_user: `新規ユーザー登録`,
    user_count: `ユーザー数`,
    password: `パスワード`,
    bank_account_name: `銀行 口座名義`,
    bank_password: `出金パスワード`,
    bank_name: `銀行名`,
    currency: `通貨`,
    create: `登録`,

    //= message modal
    to_all: `全体 送信`,
    enabled: `有効`,
    disabled: `遮断`,
    to_level: `レベル 送信`,
    select_level: `レベル選択`,
    to_agent: `変更店舗`,
    from_agent: `現在の所属`,
    select_agent: `店舗選択`,
    to_code: `コード送信`,
    select_code: `コード選択`,
    to_group: `グループ送信`,
    select_group: `グループ選択`,
    to_domain: `ドメイン送信`,
    select_domain: `ドメイン選択`,
    no_data: `データなし`,

    subject_here: `タイトル`,
    content_here: `内容`,
    send: `送信`,
    modify: `修正`,

    change_user_agent: `ユーザー店舗変更`,
    apply_to_all: `すべて適用`,
    message_to_user: `メッセージ送信`,

    bank_details: `ユーザー店舗変更`,
    create_test_user: `テストユーザー登録`,

    error_user_exist: `すでに存在しているアカウントです.`,

    select_user: `使用者を選択してください`,
    choose_recipient: `受取人を選択してください`,
    domain: `ドメイン`,
    ip: `IP`,
    device: `接続デバイス`,
    mo: `モバイル`,
    pc: `PC`,
    logout: 'logout',
    force_logout: 'Force Logout',

    last_visit_page: 'Last Visit Page',

    confirmed_date: 'confrimed date',
    bank_group: 'bank group',
    bet_365: 'bet 365',
    bet_token: 'bet token',
    win_365: 'win 365',
    win_token: 'win token',

    new_created_at: 'created date'
  }
}
