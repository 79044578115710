import { common } from '@/assets/js/translations/Common'

export const jp = {
  jp: {
    code_details: `コード詳細`,
    bank_details: `ユーザー 店舗変更`,

    users_count: `ユーザー数`,
    banned: `停止ユーザー`,
    confirmed: `正常ユーザー`,
    delete: `削除ユーザー`,
    reject: `拒否ユーザー`,
    total: `全体ユーザー`,

    code: `コード`,
    bank_name: `銀行名`,
    bank_account: `口座名`,
    bank_number: `口座番号`
  }
}
