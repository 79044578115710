import { common } from '@/assets/js/translations/Common'

export const kr = {
  kr: {
    code_details: `코드 상세정보`,
    bank_details: `유저 매장변경`,

    users_count: `유저 수`,
    banned: `중지유저`,
    confirmed: `정상유저`,
    delete: `삭제유저`,
    reject: `거부유저`,
    total: `전체유저`,

    code: `코드`,
    bank_name: `은행명`,
    bank_account: `예금주`,
    bank_number: `계좌번호`
  }
}
