import { common } from '@/assets/js/translations/Common'

export const en = {
  en: {
    code_details: `code details`,
    bank_details: `bank details`,

    users_count: `users count`,
    banned: `banned`,
    confirmed: `confirmed`,
    delete: `delete`,
    reject: `reject`,
    total: `total`,

    code: `code`,
    bank_name: `bank name`,
    bank_account: `bank account`,
    bank_number: `bank number`
  }
}
