 <template>
  <modal
    class="code-details-popup"
    :title="translate(translations, 'code details')"
    v-if="active"
    :activate="active"
    @activate="check"
  >
    <template slot="body">
      <div class="form">
        <div class="form__head">
          <div class="form__head-item">
            <div class="form__item">
              <div class="form__label">
                <span>
                  {{ translate(translations, 'code') }}
                </span>
              </div>

              <div class="form__data">
                <div
                  class="form__data-inner"
                  v-if="code"
                >
                  <span>{{ code.code }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 class="table-title">
        {{ translate(translations, 'users count') }}
      </h2>

      <tbl>
        <template slot="head">
          <tbl-td name="banned">
            {{ translate(translations, 'banned') }}
          </tbl-td>

          <tbl-td name="confirmed">
            {{ translate(translations, 'confirmed') }}
          </tbl-td>

          <tbl-td name="delete">
            {{ translate(translations, 'delete') }}
          </tbl-td>

          <tbl-td name="reject">
            {{ translate(translations, 'reject') }}
          </tbl-td>

          <tbl-td name="total">
            {{ translate(translations, 'total') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row v-if="code">
            <tbl-td name="banned">
              {{ code.users_ban }}
            </tbl-td>

            <tbl-td name="confirmed">
              {{ code.users_confirm }}
            </tbl-td>

            <tbl-td name="delete">
              {{ code.users_delete }}
            </tbl-td>

            <tbl-td name="reject">
              {{ code.users_reject }}
            </tbl-td>

            <tbl-td name="total">
              {{ code.users_total }}
            </tbl-td>
          </tbl-row>
        </template>
      </tbl>

      <h2 class="table-title">
        {{ translate(translations, 'bank details') }}
      </h2>

      <tbl>
        <template slot="head">
          <tbl-td name="bank-account-name">
            {{ translate(translations, 'bank account') }}
          </tbl-td>

          <tbl-td name="bank-number">
            {{ translate(translations, 'bank number') }}
          </tbl-td>

          <tbl-td name="bank-name">
            {{ translate(translations, 'bank name') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row v-if="code">
            <tbl-td name="bank-account-name">
              {{ code.deposit.bank_account_name }}
            </tbl-td>

            <tbl-td name="bank-number">
              {{ code.deposit.bank_number }}
            </tbl-td>

            <tbl-td name="bbank-name">
              {{ code.deposit.bank_name }}
            </tbl-td>
          </tbl-row>
        </template>
      </tbl>
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import Modal from '@/components/base/Modal'

//= translation
import { translate } from '@/assets/js/translations/Translate'
import { translations } from '@/assets/js/translations/CodeDetails'

export default {
  name: 'CodeDetails',

  components: {
    Modal
  },

  props: {
    id: [Number]
  },

  data () {
    return  {
      translations,
      active: this.id === null ? false : true
    }
  },

  computed: {
    ...mapState('code', ['codes']),

    code () {
      return this.codes[0] || null
    }
  },

  watch: {
    async id (val) {
      this.active = val === null ? false : true
      if (!this.active) return

      await this.get({ status: 'all', id: val })
    }
  },

  methods: {
    ...mapActions('code', ['get']),

    check (e) {
      this.active = e
      if (!e) {
        this.$emit('close')
      }
    }
  }
}
</script>
